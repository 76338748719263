@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}



:root {
    --bg-color: #fdfdfd;
    --text-color: #333;
    --main-color: #2febf5;
    --white-color: #fdfdfd;
    --shadow-color: rgba(0, 0, 0, .2);
}

*::selection {
    background: var(--main-color);
    color: var(--bg-color);
}

.dark-mode {
    --bg-color: #0b061f;
    --text-color: #fdfdfd;
    --shadow-color: rgba(0, 0, 0, .7);
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

body {
    background: var(--bg-color);
    color: var(--text-color);
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem 7%;
    background: transparent;
    display: flex;
    align-items: center;
    z-index: 100;
    transition: .5s;
}

.header.sticky {
    background: var(--bg-color);
    box-shadow: 0 .1rem 1rem var(--shadow-color);
    /* border-radius: 999rem 999rem;
    max-width: calc(90%);
    left: 5%;
    right: 5%;
    margin-top: 1%;
    border:2px solid #2febf5; */
}

.logo {
    font-size: 2.5rem;
    color: var(--main-color);
    font-weight: 600;
    margin-right: auto;
}

.navbar a {
    position: relative;
    font-size: 1.7rem;
    color: var(--text-color);
    font-weight: 500;
    margin-right: 3.5rem;
}

.header.sticky .navbar a {
    color: var(--text-color);
}

.header.sticky .navbar a.active {
    color: var(--main-color);
}

.navbar a.active::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 100%;
    height: .2rem;
    background: var(--main-color);
}

.header.sticky .navbar a::before {
    background: var(--main-color);
    opacity: .7;
}

#darkMode-icon {
    font-size: 2.4rem;
    color: var(--text-color);
    cursor: pointer;
}

.header.sticky #darkMode-icon {
    color: var(--text-color);
    opacity: .9;
}

#menu-icon {
    font-size: 3.6rem;
    color: var(--text-color);
    display: none;
}

section {
    min-height: 100vh;
    padding: 10rem 7% 2rem;
}

.home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: linear-gradient(45deg, rgb(181, 179, 187) 52%,transparent 50%);
}

.home .home-content {
    max-width: 44rem;
    margin-top: 100px;
}

.home-content h3 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: .3;
}

.home-content h1 {
    font-size: 5.6rem;
    font-weight: 700;
    margin-bottom: .3rem;
}

.home-content p {
    font-size: 1.6rem;
}

.home-content .social-media a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background: transparent;
    border: .2rem solid var(--main-color);
    border-radius: 50%;
    box-shadow: 0 .2rem .5rem var(--shadow-color);
    font-size: 2rem;
    color: var(--main-color);
    margin: 2.5rem 1.5rem 3rem 0;
    transition: .5s ease;
}

.home-content .social-media a:hover {
    background: var(--main-color);
    color: var(--white-color);
}

.btn {
    display: inline-block;
    padding: 1.2rem 2.8rem;
    background: var(--main-color);
    border-radius: .6rem;
    box-shadow: 0 .2rem .5rem var(--shadow-color);
    font-size: 1.6rem;
    color: var(--white-color);
    letter-spacing: .1rem;
    font-weight: 600;
    border: .2rem solid transparent;
    transition: .5s ease;
}

.btn:hover {
    background: transparent;
    color: var(--main-color);
    border-color: var(--main-color);
}


.home-img img {
    pointer-events: none;
    max-width: 480px;
    max-height: 100vh;
    border-radius: 50%;
}

span {
    color: var(--main-color);
}

.about {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.about-img img {
    width: 40vw;
    border-radius: 0% 40%;

}

.heading {
    font-size: 4.5rem;
    text-align: center;
}

.about-content h2 {
    text-align: left;
    line-height: 1.2;
}

.about-content h3 {
    font-size: 2rem;
}

.about-content p {
    font-size: 1.6rem;
    margin: 2rem 0 3rem;
}

.services {
    min-height: auto;
    padding-bottom: 10rem;
}

.services h2 {
    margin-bottom: 5rem;
}

.services .services-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.services-container .services-box {
    flex: 1 1 30rem;
    background: var(--bg-color);
    padding: 3rem 2rem 4rem;
    border-radius: 2rem;
    box-shadow: 0 .1rem .5rem var(--shadow-color);
    text-align: center;
    border-top: .6rem solid var(--main-color);
    border-bottom: .6rem solid var(--main-color);
    transition: .5s ease;
}

.services-container .services-box:hover {
    box-shadow: 0 .1rem 2rem var(--shadow-color);
    transform: scale(1.02);
}

.services-box i {
    font-size: 7rem;
    color: var(--main-color);
}

.services-box h3 {
    font-size: 2.6rem;
    transition: .5s ease;
}

.services-box:hover h3 {
    color: var(--main-color);
}

.services-box p {
    font-size: 1.6rem;
    margin: 1rem 0 3rem;
}

.portfolio {
    min-height: auto;
    padding-bottom: 10rem;
}

.portfolio h2 {
    margin-bottom: 4rem;
}

.portfolio .portfolio-container {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 2.5rem; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    display: wrap;
    flex-wrap: wrap;
}

.portfolio-container .portfolio-box {
    height: 300px;
    width: 300px;
    margin-top: 30px;
    position: relative;
    border-radius: 2rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, .1);
    overflow: hidden;
}

.portfolio-box img {
    width: 100%;
    transition: .5s ease;
}

.portfolio-box:hover img {
    transform: scale(1.5);
}

.portfolio-box .portfolio-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, .1), var(--main-color));
    color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 4rem;
    opacity: 0;
    transition: .5s ease;
}

.portfolio-box:hover .portfolio-layer {
    opacity: 1;
}

.portfolio-layer h4 {
    font-size: 3rem;
}

.portfolio-layer p {
    font-size: 1.6rem;
    margin: .3rem 0 1rem;
}

.portfolio-layer a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background: var(--white-color);
    border-radius: 50%;
}

.portfolio-layer a i {
    font-size: 2rem;
    color: #333;
}




.contact {
    min-height: auto;
    padding-bottom: 7rem;
}

.contact h2 {
    margin-bottom: 3rem;
}

.contact form {
    max-width: 70rem;
    margin: 1rem auto;
    text-align: center;
    margin-bottom: 3rem;
}

.contact form .input-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contact form .input-box input,
.contact form textarea {
    width: 100%;
    padding: 1.5rem;
    font-size: 1.6rem;
    color: var(--text-color);
    background: var(--bg-color);
    border-radius: .8rem;
    margin: .7rem 0;
    box-shadow: .2rem .2rem .2rem .2rem var(--text-color);
}

.contact form .input-box input {
    width: 49%;
}

.contact form textarea {
    resize: none;
}

.contact form .btn {
    margin-top: 2rem;
    cursor: pointer;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 7%;
    background: var(--main-color);
}

.footer-text p {
    font-size: 1.6rem;
    color: var(--white-color);
}

.footer-iconTop a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: .8rem;
    background: var(--white-color);
    border-radius: .8rem;
    border: .2rem solid var(--main-color);
    outline: .2rem solid transparent;
    transition: .5s ease;
}

.footer-iconTop a:hover {
    outline-color: var(--white-color);
}

.footer-iconTop a i {
    font-size: 2.4rem;
    color: #333;
}



@media (max-width:480px) {
    header {
        display: flex;
        flex-direction: column;
    }
     .header a{
        margin-left: auto;
     }
    header nav{
        flex-direction:row;
        transform: scale(0.7);
    }
    header .bx{
        margin-left: 320px;
        border-radius:100px;
        background-color:var(--main-color);
        padding: 13px;
    }
    .home{
        display: flex;
        flex-direction:column-reverse;
    }

    .home-img img{
        height: 300px;
        width: 300px;
    }
    section{
        display:flex;
        flex-direction:column;
        margin-top:150px;
    }
   
    section .home-content{
        margin-top:450px;
    
    }
    .home-content h1{
       font-size: xx-large;
       padding: 10px;
    }



 
  }